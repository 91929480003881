.hero {
   color: #fff;
   position: absolute;
   top: 50%;
   left: 50%;
   z-index: 3;
   color: #fff;
   text-align: center;
 
   text-transform: uppercase;
   text-shadow: 1px 1px 0 rgba(0,0,0,.75);
     -webkit-transform: translate3d(-50%,-50%,0);
        -moz-transform: translate3d(-50%,-50%,0);
         -ms-transform: translate3d(-50%,-50%,0);
          -o-transform: translate3d(-50%,-50%,0);
             transform: translate3d(-50%,-50%,0);
}



/************/
/*            Overlay           */
/************/
.overlay {
   position: absolute;
   width: 100%;
   height: 100%;
   z-index: 2;
   /* background-color: #080d15; */
   opacity: .7;
  
}


/************/
.fade-carousel .slides .slide-1  {
 height: 70vh;
 background-size: cover;
 background-position: center center;
 background-repeat: no-repeat;
}
.fade-carousel .slides .slide-1 {
 background-image: url('../../../public//img/accountingservices.jpg'); 
}


/************/
/*          Media Queries       */
/************/
@media screen and (min-width: 980px){
   .hero { width: 980px; }    
}
@media screen and (max-width: 640px){
   .hero h3 { font-size: 16px; }    
}
.main_header {
 position: fixed;
 top: 0px;
 max-height: 70px;
 z-index: 999;
 width: 100%;
 background: none;
 overflow: hidden;
 -webkit-transition: all 0.3s;
 transition: all 0.3s;
 opacity: 0;
 top: -100px;
 padding-bottom: 6px;
}
p{
   text-align: justify;
}