/* .consultation-card {
    width: 100%;
    max-width: 400px; 
    margin: 0 auto; 
    padding: 20px;
    background-color: #ffffff; 
    border-radius: 10px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  }
  
  .consultation-card p {
    margin: 0; 
  }
  
  .book-now-button {
    display: block;
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    background-color: #007bff; 
    color: #ffffff; 
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .book-now-button:hover {
    background-color: #0056b3; 
  }
   */
   .consultation-card {
    position: relative;
    width: 100%;
    max-width: 1300px; /* Same width */
    height: 300px; /* Consistent height */
    border-radius: 10px;
    overflow: hidden;
    margin: 20px auto;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    background: url('https://img.freepik.com/premium-photo/finance-document-with-infographic-analisys-audit-process_151013-224.jpg?w=1380') 
      no-repeat center center/cover;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Overlay for readability */
  .card-overlay {
    position: absolute;
    inset: 0;
    background: rgba(0, 0, 0, 0.5); /* Semi-transparent dark overlay */
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .card-content {
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 20px;
  }
  
  /* Text Styling */
  .text-container {
    color: white;
    margin-bottom: 15px;
  }
  
  .text-container h2 {
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  /* Button Styling */
  .custom-btn {
    color: #3E409A;
    border: 2px solid #3E409A;
    background: white;
    padding: 12px 20px;
    font-size: 16px;
    font-weight: bold;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .quick-btn {
    background-color: #3E409A;
    color: white;
    /* border: 2px solid white; */
  }
  
  .quick-btn:hover {
    background-color: white;
    color: #3E409A;
  }
  
  /* Responsive Design */
  @media screen and (max-width: 600px) {
    .consultation-card {
      height: 250px;
    }
    .text-container h2 {
      font-size: 22px;
    }
    .custom-btn {
      padding: 7px 15px;
    }
  }
  